import store from '../../store'
import {httpReq} from '@/htttpReq'
import router from '../../../router'
import {getNextKeyByKey} from '../../../helpers/collection'

export default {
    namespaced: true,
    state: {
        orderId: 0,
        activeModal: false,
        selectedItems: [],
        // types: {},
        // styles: {},
        // grades: {},
        // colours: {},
        // typeId: null,
        // styleId: null,
        // gradeId: null,
        // colourId: null,
        // fails: [],

    },
    getters: {
        getState: state => key => {
            return _.has(state, key) ? state[key] : null
        },
        // selections(state) {
        //     return state.selections
        // }
    },
    mutations: {
        set(state, data) {
            state[data.s] = data.p
        },
    },
    actions: {
        goToOrderPreview({state}, orderId) {
            router.push(`/order-preview/${orderId}`)
            localStorage.setItem('order_id', orderId)
        },
        goToBulkEdit({state}, orderId) {
            router.push(`/order-bulk-edit/${orderId}`)
        },
        update({state, commit, dispatch}, params) {

            httpReq.put('/order/bulk-update', {

                step: params.type,
                type_id: state.typeId,
                style_id: state.styleId,
                grade_id: state.gradeId,
                colour_id: state.colourId,
                insert_colour_id: state.insert_colourId,
                items: state.selectedItems

            }).then((response) => {

                if (response.data.next_step)
                {
                    commit('set', {
                        s: 'activeModal',
                        p: response.data.next_step
                    })

                    if (response.data.next_step === 'options')
                        commit('set', {
                            s: 'activeOption',
                            p: response.data.option
                        })
                    commit('selections', {
                        b: response.data.next_step + 's',
                        o: response.data.option,
                        d:{
                            loaded: true,
                            items: response.data.items
                        }

                    })
                }
                else {
                    state.fails = []
                    state.activeModal = false
                    store.dispatch('orderPreview/getPreview')
                    commit('clean')
                }


                // if (_.size(response.data.fails) > 0) {
                //     state.fails = response.data.fails
                //     dispatch(
                //         'getFailedSG',
                //         {
                //             items: response.data.fails,
                //             needOrderFields: needOrderFields,
                //             nextStep: nextStep
                //         }
                //     )
                // } else {
                //     state.fails = []
                //     state.activeModal = false
                //     // location.reload()
                //     store.dispatch('orderPreview/getPreview')
                // }
                // if (_.size(response.data.saved) > 0) {
                //     store.dispatch('orderPreview/getPreview', {showPreloader: false});
                // }
            }).catch(error => {
                store.commit('errorsModal/openModal', error.response.data.errors)
                if (error.response.status == '401') {
                    localStorage.removeItem('user-token')
                    localStorage.removeItem('nameUser')
                    window.location = '/login'
                }
            }).finally((response) => {
                //store.commit('preloader/preloader', false)
            })


            return

            // let sendData = [],
            //     needOrderFields = {
            //         type: 'type_id',
            //         style: 'style_id',
            //         grade: 'grade_id',
            //         colour: 'colour_id'
            //     },
            //     itemToUpdate = {},
            //     nextStep = getNextKeyByKey(params.type, needOrderFields)
            //
            // if (_.size(state.fails) === 0) {
            //     delete needOrderFields[params.type]
            //
            //     _.forEach(state.selectedItems, (v) => {
            //
            //         itemToUpdate = {
            //             id: v,
            //             type: state.typeId,
            //             style: state.styleId,
            //             grade: state.gradeId,
            //             colour: state.colourId
            //         }
            //
            //         _.forEach(store.getters['orderPreview/items']['locations'], (items, location) => {
            //             _.forEach(items.items, (items) => {
            //                 _.forEach(items, (item) => {
            //                     if (_.toInteger(item.item_id) === v) {
            //                         _.forEach(needOrderFields, (v, k) => {
            //                             itemToUpdate[k] = _.toInteger(item[v])
            //                         })
            //                     }
            //                 })
            //             })
            //         })
            //
            //         sendData.push(itemToUpdate)
            //
            //     })
            // } else {
            //     sendData = _.reduce(state.fails, (r, v, k) => {
            //         v[params.type] = state[params.type + 'Id']
            //         r[k] = v
            //         return r
            //     }, {})
            // }
            //
            // sendData = {
            //     order_id: state.orderId,
            //     items: sendData
            // }
            //
            // store.commit('preloader/preloader', true)
            //
            // httpReq.put('/order/bulk-update', sendData).then((response) => {
            //     if (_.size(response.data.fails) > 0) {
            //         state.fails = response.data.fails
            //         dispatch(
            //             'getFailedSG',
            //             {
            //                 items: response.data.fails,
            //                 needOrderFields: needOrderFields,
            //                 nextStep: nextStep
            //             }
            //         )
            //     } else {
            //         state.fails = []
            //         state.activeModal = false
            //         // location.reload()
            //         store.dispatch('orderPreview/getPreview')
            //     }
            //     // if (_.size(response.data.saved) > 0) {
            //     //     store.dispatch('orderPreview/getPreview', {showPreloader: false});
            //     // }
            // }).catch(error => {
            //     store.commit('errorsModal/openModal', error.response.data.errors)
            //     if (error.response.status == '401') {
            //         localStorage.removeItem('user-token')
            //         localStorage.removeItem('nameUser')
            //         window.location = '/login'
            //     }
            // }).finally((response) => {
            //     //store.commit('preloader/preloader', false)
            // })
        },
        goToNextStep({state, dispatch}, params) {
            let needOrderFields = {
                type: 'type_id',
                style: 'style_id',
                grade: 'grade_id',
                colour: 'colour_id'
            }
            if (_.size(state[params.nextStep + 's'])) {
                state.activeModal = params.nextStep
            } else {
                params.nextStep = getNextKeyByKey(params.nextStep, needOrderFields)
                if (params.nextStep !== false) {
                    dispatch('goToNextStep', params)
                } else {
                    dispatch('initSteps')
                    state.activeModal = false
                }
            }
        },
        getFailedSG({state, dispatch}, params) {
            httpReq.get('/order/bulk-failed-tsg',
                {
                    params: {
                        order_id: state.orderId,
                        items: state.fails
                    }
                }
            ).then(response => {
                if (Object.keys(response.data).length) {
                    state.styles = response.data.styles
                    state.grades = response.data.grades

                    if (params.nextStep !== false) {
                        dispatch(
                            'goToNextStep',
                            {
                                nextStep: params.nextStep,
                                needOrderFields: params.needOrderFields
                            }
                        )
                    } else {
                        state.activeModal = false
                    }
                }
            }).catch(error => {
                if (_.toInteger(error.response.status) === '401') {
                    localStorage.removeItem('user-token')
                    localStorage.removeItem('nameUser')
                    window.location = '/login'
                }
                store.commit('errorsModal/openModal', error.response.data.errors)
            }).finally(() => {
                store.commit('preloader/preloader', false)
            })
        },

        loadSelections({state}, data){

            if (state.selections[data.m].loaded)
                return

            httpReq.get(
                '/order/bulk-' + data.m,
                {
                    params: {
                        items_id: _.toArray(state.selectedItems)
                    }
                }
            ).then(response => {
                store.commit('bulkEdit/selections', {
                    b: data.m,
                    d: {
                        loaded: true,
                        items: response.data
                    }
                })
            }).catch(error => {
                if (_.toInteger(error.response.status) === '401') {
                    localStorage.removeItem('user-token')
                    localStorage.removeItem('nameUser')
                    window.location = '/login'
                }
                store.commit('errorsModal/openModal', error.response.data.errors)
            }).finally(() => {
            })

        },


        // initSteps({dispatch, state}, params) {
        //
        //     params = {
        //         order_id: state.orderId,
        //         items_id: _.toArray(state.selectedItems)
        //     }
        //
        //     dispatch('getAllowedTypes', params)
        //     dispatch('getAllowedStyles', params)
        //     dispatch('getAllowedGrades', params)
        //     dispatch('getAllowedColour', params)
        //
        // },

        // getAllowedColour({state}, params) {
        //     httpReq.get(
        //         '/order/bulk-colours',
        //         {
        //             params: {
        //                 order_id: params.order_id,
        //                 items_id: _.toArray(params.items_id)
        //             }
        //         }
        //     ).then(response => {
        //         if (Object.keys(response.data).length) {
        //             state.colours = response.data.data
        //             store.commit('colors/colors', response.data)
        //         }
        //     }).catch(error => {
        //         if (_.toInteger(error.response.status) === '401') {
        //             localStorage.removeItem('user-token')
        //             localStorage.removeItem('nameUser')
        //             window.location = '/login'
        //         }
        //         store.commit('errorsModal/openModal', error.response.data.errors)
        //     }).finally(() => {
        //         store.commit('preloader/preloader', false)
        //     })
        // },
        // getAllowedStyles({state}, params) {
        //     httpReq.get(
        //         '/order/bulk-styles',
        //         {
        //             params: {
        //                 order_id: params.order_id,
        //                 items_id: _.toArray(params.items_id)
        //             }
        //         }
        //     ).then(response => {
        //         if (Object.keys(response.data).length) {
        //             state.styles = response.data.data
        //         }
        //     }).catch(error => {
        //         if (_.toInteger(error.response.status) === '401') {
        //             localStorage.removeItem('user-token')
        //             localStorage.removeItem('nameUser')
        //             window.location = '/login'
        //         }
        //         store.commit('errorsModal/openModal', error.response.data.errors)
        //     }).finally(() => {
        //         store.commit('preloader/preloader', false)
        //     })
        // },
        // getAllowedGrades({state}, params) {
        //     httpReq.get(
        //         '/order/bulk-grades',
        //         {
        //             params: {
        //                 order_id: params.order_id,
        //                 items_id: _.toArray(params.items_id)
        //             }
        //         }
        //     ).then(response => {
        //         if (Object.keys(response.data).length) {
        //             state.grades = response.data.data
        //         }
        //     }).catch(error => {
        //         if (_.toInteger(error.response.status) === '401') {
        //             localStorage.removeItem('user-token')
        //             localStorage.removeItem('nameUser')
        //             window.location = '/login'
        //         }
        //         store.commit('errorsModal/openModal', error.response.data.errors)
        //     }).finally(() => {
        //         store.commit('preloader/preloader', false)
        //     })
        // },
        // getAllowedTypes({state}, params) {
        //     httpReq.get(
        //         '/order/bulk-types',
        //         {
        //             params: {
        //                 order_id: params.order_id,
        //                 items_id: _.toArray(params.items_id)
        //             }
        //         }
        //     ).then(response => {
        //         if (Object.keys(response.data).length) {
        //             state.types = response.data.data
        //         }
        //     }).catch(error => {
        //         if (_.toInteger(error.response.status) === '401') {
        //             localStorage.removeItem('user-token')
        //             localStorage.removeItem('nameUser')
        //             window.location = '/login'
        //         }
        //         store.commit('errorsModal/openModal', error.response.data.errors)
        //     }).finally(() => {
        //         store.commit('preloader/preloader', false)
        //     })
        // }
    },
}
