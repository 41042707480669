<template>
    <div class="container">
        <div class="row">
            <div class="col-md-2 col-sm-3 col" v-if="items.actions && canEdit">
                <div class="thumbnailsTable">
              <span class="thumbnailsTable__img" @click="addLocation(items)">
                <svg-icon name="plus-circle" class="large_icon"/>
              </span>
                    <span class="thumbnailsTable__text" @click="addLocation(items)">Add a New Item</span>
                </div>
            </div>

            <div class="col-md-2 col-sm-3 col" v-if="items.actions && canEdit">
                <div class="thumbnailsTable">
              <span class="thumbnailsTable__img" @click="addAdditionalAccessories(items)">
                <svg-icon name="stream" class="large_icon"/>
              </span>
                    <span class="thumbnailsTable__text"
                          @click="addAdditionalAccessories(items)">Add a New Accessory</span>
                </div>
            </div>

            <div class="col-md-2 col-sm-3 col"
                 v-if="(code === 'DRFT' && items.apply_set) || (code === 'REVW' && items.apply_set)">
                <div class="thumbnailsTable" @click="openSets">
              <span class="thumbnailsTable__img">
                <svg-icon name="share-alt" class="large_icon"/>
              </span>
                    <span class="thumbnailsTable__text">Apply Set</span>
                </div>
            </div>

            <div class="col-md-2 col-sm-3 col"
                 v-if="(showBulk && items.actions && canEdit)">
                <div class="thumbnailsTable" @click="bulkEdit(items.order_id)">
              <span class="thumbnailsTable__img">
                <svg-icon name="cogs" class="large_icon"/>
              </span>
                    <span class="thumbnailsTable__text">Bulk Edit</span>
                </div>
            </div>

            <div class="col-md-2 col-sm-3 col"
                 v-if="items.actions && canEdit && items.customer">
                <div class="thumbnailsTable" @click="openSettings">
              <span class="thumbnailsTable__img">
                <svg-icon name="sliders-h" class="large_icon"/>
              </span>
                    <span class="thumbnailsTable__text">Order Settings</span>
                </div>
            </div>

            <div class="col-md-2 col-sm-3 col" v-if="items.actions && canEdit">
                <div class="thumbnailsTable" @click="openCustomerWindow">
              <span class="thumbnailsTable__img">
                <svg-icon name="user-plus" class="large_icon"/>
              </span>
                    <span class="thumbnailsTable__text">Customer Details</span>
                </div>
            </div>

            <div class="col-md-2 col-sm-3 col">
                <div class="thumbnailsTable" @click="backStep">
              <span class="thumbnailsTable__img">
                <svg-icon name="chalkboard" class="large_icon"/>
              </span>
                    <span class="thumbnailsTable__text">Back to Dashboard</span>
                </div>
            </div>
        </div>

      <transition name="fade" v-if="warning && warning.length > 0 && items.state_id != 'ORD'">
        <div class="row">
          <div class="col-12">
            <ul class="w-100">
              <li  class="alert alert-warning w-100" v-for="w in warning">
                <svg-icon name="exclamation-triangle" /> {{w.message}}
              </li>
            </ul>
          </div>
        </div>
      </transition>

        <div class="row">
            <div class="col-12">
                <h2 class="titlePage_subtitle titlePage">Product Summary
                  <span class="text_small cursor_pointer" v-if="items.reference && items.actions && canEdit" @click="openCustomerWindow">(Ref: {{items.reference}})</span>
                  <span class="text_small" v-else-if="items.reference" >(Ref: {{items.reference}})</span>
                </h2>

                <div class="row align-items-start">

                    <div class="col-12" v-if="!items.locations">
                        <p class="text text_italic text_green">No location added yet</p>
                    </div>

<!--                  <draggable  @end="updateSortOrder" :handle="[locationsAreLoading? '.no-handle': '.handle-icon']" v-model="locations" class="row col-12" draggable=".drag-item" item-key="id">
                    <template #item="{element}">-->

                    <div class="col-lg-4 col-sm-6 col-12 drag-item"
                         v-for="(location, index) in locations"
                         :key="location.name">
                      <Location :add-item="addItem"
                                  :calc-price="calcPrice" :can-edit="canEdit"
                                  :code="code"
                                  :counter="counter"
                                  :delete-item="deleteItem"
                                  :format-name="formatName"
                                  :open-accessories="openAccessories"
                                  :location="location"
                                  :prepare-edit="prepareEdit"/>
                    </div>
<!--                  slot="footer"-->
                    <div  class="col-lg-4 col-sm-6 col-12"
                         v-if="items.accessories && items.accessories.length > 0">
                      <div class="orderItem">
                        <div class="orderItem__listItems">
                          <h3 class="orderItem__listTitle text_green text_center">Additional Accessories</h3>

                          <div class="tw-grid">

                            <div class="toggleList"
                                 v-for="(accessory, accKey) in filterACCKeyGrateThanOne"
                                 :class="{'toggleList__title_empty': code !== 'DRFT'  && code !== 'REVW'}"
                            >
                              <div class="orderItem__iconBox">
                              <img v-if="accessory.image != 'No Image'" :src="accessory.image"
                                   class="orderItem__img"></div>
                              <div class="toggleList__title d-flex justify-content-between pr-0">

                                <span>
                                  {{ Number(accessory.qty).toFixed(0) }} x {{ accessory.name }} - {{ accessory.colour_name }}
                                </span>
                                <div class="d-flex flex-column">
                                  <div class="d-flex">
                                    <span class="set__itemDelete"
                                           v-if="code === 'DRFT'  || code === 'REVW'"
                                    >
                                    </span>

                                    <span class="set__itemDelete mr-2"
                                          @click="editAdditionalAccessory(accessory, accKey)"
                                          v-if="items.actions && canEdit"
                                    >
                                        <svg-icon name="edit"/>
                                    </span>

                                    <span class="set__itemDelete"
                                            @click="deleteItemAccessory(accessory)"
                                            v-if="items.actions && canEdit"
                                      >
                                      <svg-icon name="solid/trash"/>
                                    </span>


                                  </div>
                                  <div class="mt-2">
                                    <p v-if="can(abilityNames.SEE_PRICE)" class="toggleList__price text_green">
                                      ${{ Number(accessory.price).toFixed(2) }}
                                    </p>
                                  </div>
                                </div>
                              </div>

                            </div>
                            <div v-if="can(abilityNames.SEE_PRICE)" class="orderItem__price tw-flex tw-justify-between tw-items-center">
                              <div></div>
                              <div @click="isExpandAdditionalAccessories = !isExpandAdditionalAccessories" v-if="items.accessories.length > 2" class="tw-inline-flex tw-cursor-pointer tw-text-2xl hover:tw-text-green-600 tw-transition tw-transition-all">
                                <svg-icon :name="isExpandAdditionalAccessories? 'solid/chevron-up': 'solid/chevron-down'"/>
                              </div>
                              <span>aud ${{ accessoriesTotal() }}</span>

                            </div>

                          </div>

                          <!--<div class="orderItem__price" v-if="item.items.standard">-->
                          <!--aud ${{calcPrice(item.amount)}}-->
                          <!--</div>-->
                        </div>

                        <div class="orderItem__footer" v-if="code === 'DRFT' || code === 'REVW'">
                          <button class="btn_link" type="button"
                                  @click="addAdditionalAccessories(items, accessory)">
                            <svg-icon name="plus"/>
                            Add Additional Accessory
                          </button>
                        </div>
                      </div>

                    </div>
<!--                    </template>
                  </draggable>-->

                </div>
            </div>
        </div>

        <div class="row" v-if="incomplete">
            <div class="col-12">
                <p id="incomplete" class="text_red">* Highlighted items require review!</p>
            </div>
        </div>

        <transition name="slide" mode="out-in">
            <div class="row">
                <order-summary/>
                <div class="col-md-4 col-lg-6  col-sm-12" v-if="items.history && items.history.length > 0">
                    <h2 class="titlePage_subtitle titlePage titlePage_mb">Order Review History</h2>
                    <div class="row" v-for="(i) in items.history">
                        <div class="col-3">{{$filters.formatDate(i.created_at)}}</div>
                        <div class="col-9">{{i.comment}}</div>
                    </div>
                </div>
            </div>
        </transition>

        <div class="row" v-if="items.actions">
            <div class="col">
                <button class="btn btn_default tw-inline-flex tw-items-center tw-space-x-1" type="button"
                        @click.prevent="orderReport()"
                        :disabled="this.incomplete"
                        key="Specification"
                        v-if="checkButton('Specification')"
                >
                    <svg-icon name="solid/memo"/> <span>Specification</span>
                </button>


                <router-link :to="{name: 'orderList', query: {orderType: checkPreviousList()}}"
                             class="btn btn_default tw-inline-flex tw-items-center tw-space-x-1"
                             :class="{btn_disabled: this.incomplete}"
                             tag="button"
                             :disabled="this.incomplete"
                ><svg-icon name="solid/check"/><span>Done</span>
                </router-link>

                <button class="btn btn_default tw-inline-flex tw-items-center tw-space-x-1" type="button"
                        @click.prevent="cloneActions()"
                        :disabled="this.incomplete"
                        v-if="checkButton('Clone')"
                        key="Clone"
                ><svg-icon name="solid/clone"/><span>Clone</span>
                </button>

                <button class="btn btn_default tw-inline-flex tw-items-center tw-space-x-1" type="button"
                        v-if="checkButton('Submit order')"
                        @click.prevent="isSubmit"
                        key="submit-order"
                        :disabled="disabledBtn() || this.incomplete"
                ><svg-icon name="solid/industry-windows"/><span>Submit Order</span>
                </button>

                <button class="btn btn_default tw-inline-flex tw-items-center tw-space-x-1" type="button"
                        v-if="checkButton('Cancel')"
                        @click.prevent="cancelActions() || this.incomplete"
                        key="cancel"
                ><svg-icon name="solid/ban"/><span>Cancel</span>
                </button>

                <button class="btn btn_default tw-inline-flex tw-items-center tw-space-x-1" type="button"
                        v-if="checkButton('Send estimate')"
                        @click.prevent="sendEstimateActions()"
                        :disabled="disabledBtn() || this.incomplete"
                        key="send-estimate"
                ><svg-icon name="solid/clipboard-list"/><span>Send Estimate</span>
                </button>

                <button class="btn btn_default tw-inline-flex tw-items-center tw-space-x-1" type="button"
                        v-if="checkButton('Send quote')"
                        @click.prevent="sendQuoteActions()"
                        key="send-quote"
                        :disabled="disabledBtn() || this.incomplete"
                ><svg-icon name="solid/tape"/><span>Send Quote</span>
                </button>

                <button class="btn btn_default tw-inline-flex tw-items-center tw-space-x-1" type="button"
                        v-if="checkButton('Delete')"
                        @click.prevent="deleteActions()"
                        :disabled="this.incomplete"
                        key="delete"
                ><svg-icon name="solid/trash-alt"/><span>Delete</span>
                </button>

            </div>
        </div>

        <Modal :isOpen="selectModal" @onClose="openWarning" @onBack="backStepInCreate" :showBackButton="showBackButton">
<!--            >>>{{ isCompleted? 'Edittttt': 'NEWWW' }} | <pre>{{ JSON.stringify(stepsHistory) }}</pre>-->

          <!-- TODO:Remove this Kiruba          -->
          <!--<select-location v-if="currentRouteName === 'order-preview'"-->
          <!--class="modal_content"/>-->
        123  {{currentStep}}
          <select-location v-if="currentStep === 'Location'"
                           class="modal_content"/>

          <!--<select-type :createdOrder="true"-->
          <!--v-else-if="currentRouteName === 'order-preview-location'"-->
          <!--class="modal_content"/>-->
          <select-type :createdOrder="true"
                       v-else-if="currentStep=== 'Type'"
                       :actionLinksPortalTarget="actionLinksPortalTarget"
                       class="modal_content"/>

          <!--<select-style :createdOrder="true"-->
          <!--v-else-if="currentRouteName === 'order-preview-type'"-->
          <!--class="modal_content"/>-->
          <select-style :createdOrder="true"
                        v-else-if="currentStep === 'Style'"
                        class="modal_content"/>

          <!--<select-grade v-else-if="currentRouteName === 'order-preview-style'"-->
          <!--class="modal_content"/>-->
          <select-grade v-else-if="currentStep === 'Grade'"
                        class="modal_content"/>

          <!--<select-package v-else-if="currentRouteName === 'order-preview-grade'"-->
          <!--class="modal_content"/>-->

          <!--<select-colors v-else-if="currentRouteName === 'order-preview-package'"-->
          <!--class="modal_content"/>-->
          <select-colors v-else-if="currentStep === 'Frame Colour'"
                         class="modal_content"/>

          <!--<select-insert-colors v-else-if="currentRouteName === 'order-preview-color'"-->
          <!--class="modal_content"/>-->

          <select-insert-colors v-else-if="currentStep === 'Insert Colour'"
                                class="modal_content"/>

          <!--<select-options-->
          <!--v-else-if="currentRouteName === 'order-preview-insert-color' || currentRouteName === 'order-preview-option'"-->
          <!--class="modal_content"-->
          <!--:order="true"-->
          <!--&gt;</select-options>-->
          <select-options
                  v-else-if="currentStep == 'Option' || currentStep === 'All Options'"
                  class="modal_content"
                  :order="true"
                  :actionLinksPortalTarget="actionLinksPortalTarget"
          ></select-options>

<!--          <p class="text text_white_1" v-if="currentPosition.item.id"><span class="preloader__img"-->
<!--                                                                          v-if="updatePrice"><font-awesome-icon-->
<!--                  icon="spinner"/></span> $ {{itemPrice}}-->
<!--          </p>-->

<!--          <button class="btn btn_transparent" type="button" @click="openWarning">Cancel</button>-->

<!--          <button class="btn btn_transparent" type="button"-->
<!--                  v-if="currentRouteName !== 'order-preview' && !isUpdate"-->
<!--                  @click.prevent="backStepInCreate"-->
<!--          >Back-->
<!--          </button>-->

          <template v-slot:footer>
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <portal-target :name="actionLinksPortalTarget">
                </portal-target>
              </div>
              <div>
                <p class="text text_white_1" v-if="currentPosition.item.id && can(abilityNames.SEE_PRICE)"><span class="preloader__img"
                                                                                  v-if="updatePrice"><svg-icon name="spinner"/></span> $ {{itemPrice}}
                </p>
              </div>
            </div>
          </template>
        </Modal>

        <transition name="fade" mode="out-in">
            <apply-set-in-order v-if="applySet.openModal"></apply-set-in-order>
        </transition>

        <transition name="fade" mode="out-in">

            <additional-accessories v-if="additionalAccessory.open"></additional-accessories>
        </transition>

        <transition name="fade">
            <delete-modal-global v-if="deleteGlobalOpen"></delete-modal-global>
        </transition>

        <transition name="fade">
            <warning-modal v-if="warningModal" @close="closeSelect" title="You did not save changes, would you like to save?">
              <template v-slot:actions>
                <button v-if="!isStepOnTypeStyleGradeColour" class="btn btn_transparent mx-0" @click="saveChanges"><svg-icon name="solid/floppy-disk" /> Save</button>
                <button class="btn btn_transparent mx-0" @click="discardChanges"><svg-icon name="solid/ban" /> Discard Changes</button>
<!--                <button class="btn btn_transparent" @click="cancelWarning">Cancel</button>-->
              </template>
            </warning-modal>
        </transition>

        <transition name="fade">
            <cancel-order v-if="cancelOpen"></cancel-order>
        </transition>

        <transition name="fade">
            <delete-order v-if="deleteOpen"></delete-order>
        </transition>

        <transition name="fade">
            <clone-order @reRenderLocations="$store.dispatch('orderPreview/getPreviewLessInfo', {silent: false})" v-if="cloneWindow"></clone-order>
        </transition>

        <transition name="fade">
            <estimate-quote v-if="estimateQuote"></estimate-quote>
        </transition>

        <transition name="fade">
            <total-price-window v-if="openTotalOrder"></total-price-window>
        </transition>

        <transition name="fade">
            <settings-window v-if="openSettingsOrder"></settings-window>
        </transition>

        <transition name="fade">
            <div class="modal modal_wrap" v-if="customerDetails.customerDetailsWindow">
                <div class="modal_popup modal_full modal_p90">
                    <div class="container modal_content">
                        <customer-details :modal="true"></customer-details>
                    </div>
                </div>
            </div>
<!--            <modal-success v-if="successModal"></modal-success>-->
        </transition>
     </div>
</template>

<script>
    import {defineAsyncComponent} from "vue";
    import router from "../../router";
    import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
    import bulkEdit from '../../store/modules/order/bulkEdit';
    import {changePriceForm} from '../../helpers/aroundPrice';
    import draggable from 'vuedraggable'
    import { ContentLoader } from 'vue-content-loader'
    import Location from './Location'

    const SelectLocation = defineAsyncComponent(() => import('./SelectLocation'));
    const SelectAccessories = defineAsyncComponent(() => import('./SelectAccessories'));
    const SelectType = defineAsyncComponent(() => import('./SelectType'));
    const SelectStyle = defineAsyncComponent(() => import('./SelectStyle'));
    const SelectGrade = defineAsyncComponent(() => import('./SelectGrade'));
    const SelectPackage = defineAsyncComponent(() => import('./SelectPackage'));
    const SelectColors = defineAsyncComponent(() => import('./SelectColors'));
    const SelectInsertColors = defineAsyncComponent(() => import('./SelectInsertColors'));
    const SelectOptions = defineAsyncComponent(() => import('./SelectOptions'));

    const ApplySet = defineAsyncComponent(() => import('./applySets/ApplySetInOrder'));

    const WarningModal = defineAsyncComponent(() => import('@/components/modals/WarningModal'));
    const CancelOrder = defineAsyncComponent(() => import('@/components/modals/CancelOrder'));
    const DeleteOrder = defineAsyncComponent(() => import('@/components/modals/DeleteOrder'));
    const CloneOrder = defineAsyncComponent(() => import('@/components/modals/CloneOrder'));
    const EstimateQuote = defineAsyncComponent(() => import('@/components/modals/EstimateQuote'));
    const DeleteModalGlobal = defineAsyncComponent(() => import('@/components/modals/DeleteModalGlobal'));
    const SettingsWindow = defineAsyncComponent(() => import('@/components/modals/SettingsWindow'));
    const TotalPriceWindow = defineAsyncComponent(() => import('@/components/modals/TotalPriceWindow'));

    const CustomerDetails = defineAsyncComponent(() => import('@/components/orders/customerDetails/CustomerDetails'));

    const OrderSummary = defineAsyncComponent(() => import('./orderSummaryTable/OrderSummary'));
    const AdditionalAccessories = defineAsyncComponent(() => import ('./additionalAccessory/AdditionalAccessories'));

    const ModalSuccess = defineAsyncComponent(() => import('../modals/SuccessChangeCustomer'));

    const ApplySetInOrder = defineAsyncComponent(() => import("./applySets/ApplySetInOrder"));

    const Modal = defineAsyncComponent(() => import('@/components/modals/Generic'))
    import * as abilityNames from '@/components/../helpers/abilityNames'
    import store from "@/store/store";
    import {eventBus} from "../../main";


    export default {
        name: "OrderPreview",

        components: {
            Location,
            SettingsWindow,
            TotalPriceWindow,
            DeleteModalGlobal,
            OrderSummary,
            SelectLocation,
            SelectType,
            SelectStyle,
            SelectGrade,
            SelectPackage,
            SelectColors,
            SelectInsertColors,
            SelectOptions,
            WarningModal,
            SelectAccessories,
            CancelOrder,
            DeleteOrder,
            CloneOrder,
            EstimateQuote,
            ApplySetInOrder,
            CustomerDetails,
            ModalSuccess,
            AdditionalAccessories,
            Modal,
            draggable,
            ContentLoader,
        },

        data() {
            return {
                abilityNames,
                isExpandAdditionalAccessories: false,
                modals: {
                    selectLocation: true,
                    selectType: false,
                    selectStyle: false,
                    selectGrade: false,
                    selectColors: false,
                    selectInsertColors: false,
                    selectOptions: false,
                    accessories: false,
                },
                exchangeData: '',
                showBackButton: false,
                currentRouteName: '',
                accessoriesListCount: '',
                editAccessoryData: '',
                editAccessory: false,
                showBulk: false,
                code: '',
                ask_submit: false,
                submit_id: 0,
                locations: [],
                actionLinksPortalTarget: 'order-preview-footer-left',
                warning: []

            }
        },

        watch: {
            exchangeData() {

                _.forEach(this.modals, (v, k) => {
                    this.modals[k] = false
                });
                this.modals[this.exchangeData] = true
            },

            '$route'(to, from) {
                let oldPath = from.path,
                    newPath = to.path,
                    oldRoute = _.split(_.trimStart(oldPath, '/'), '/'),
                    newRoute = _.split(_.trimStart(newPath, '/'), '/');
                if (newRoute[0] === 'order-preview') {
                    if (newRoute.length < oldRoute.length) {
                        this.$store.dispatch('app/popBreadcrumb');
                    }
                }
                this.currentRouteName = to.name;
            },

            items() {
              this.setLocations(_.sortBy(this.items.locations, 'sort_order') || [])

              if (Object.keys(this.items).length > 1) {
                    let elems = _.filter(this.items.locations, (el) => {
                        return el.name !== 'Accessories'
                    });

                  var all = _.flatMap(this.items.locations, l => {
                    return l.items.standard
                  })

                  this.warning = _.filter(this.warning, i => {return i.type !== 'BasiX-22'})
                  if (_.filter(all, i => { return i && i.style_id && i.style_id === 46 }).length > 0)
                    this.warning = [...this.warning, {
                      type: 'BasiX-22',
                      message: 'Order contains BasiX products that have been discontinued or specification has changed'
                    }]


                  this.code = _.get(this.items, 'status.code', '');
                    if (this.items.locations) {
                        for (let k in elems) {
                            if (_.size(elems[k].items.standard) > 1) {
                                this.showBulk = true;
                            }
                            else if (_.size(elems[k].items.custom) > 1) {
                                this.showBulk = true;
                            }
                            else if (_.size(elems[k].items.custom) > 0 && _.size(elems[k].items.standard) > 0) {
                                this.showBulk = true;
                            }
                            else this.showBulk = false;
                        }
                        if (Object.keys(elems).length > 1) {
                            this.showBulk = true;
                        }
                        if (elems.length === 0) {
                            this.showBulk = false;
                        }
                    }
                    else this.showBulk = false;
                }
            },

            currentPosition: {
              handler: function (val, oldVal) {
                //Check if new location likely to be added. using create new item menu
                if((oldVal.location !== '' && val.location === '')) {
                  // if((oldVal.location !== '' && val.location === '') && this.createOrder) {
                  this.reloadLocation(oldVal.location.trim())
                }

                if((oldVal.location_org !== '' && val.location_org === '') && _.trim(oldVal.location_org) !== _.trim(oldVal.location)) {
                  this.reloadLocation(oldVal.location_org)
                }

              },
              deep: true,
            },
            currentStep: {
              handler: function (val, oldVal) {
                let hideOnSteps = ['All Options', 'Location']
                let show = !hideOnSteps.includes(this.currentStep)
                if(oldVal === 'All Options' && val === 'Location') show = true
                this.showBackButton = show //&& !this.currentPosition.id
              },
              immediate: true
          }

        },

        methods: {
            ...mapActions('bulkEdit', ['goToBulkEdit']),
            ...mapActions('orderPreview', ['reloadLocation', 'sortLocations']),
            // ...mapActions('orderPosition', ['rollbackStepToAllOptions']),
            checkButton(n){
                let a = this.items.actions_sync.filter(i => {
                  return i.name == n
                });
                return a.length > 0
            },
          checkPreviousList(){
            let q = localStorage.getItem('ordersListType');
            if (q === undefined)
              q = 'all'
            if (q === 'quotesEstimatesAndDrafts')
              q = 'quote-and-estimate'
            return q

          },
            updateSortOrder(e) {
              if(e.oldIndex !== e.newIndex) return this.sortLocations(this.locations)
            },
            // cancelWarning() {
            //   this.$store.commit('createdOrder/warningModal', false);
            //
            //   if(this.isCompleted && this.currentStep === 'Option') {
            //     this.rollbackStepToAllOptions()
            //   }
            // },
            saveChanges() {
              this.$store.commit('createdOrder/warningModal', false);
              this.$store.dispatch('orderPosition/save', 0);
            },
            discardChanges() {
              this.closeSelect()
              this.$store.commit('createdOrder/updateName', '');
              this.$store.commit('createdOrder/warningModal', false);
              this.$store.commit('createdOrder/cleanOrderObj');
              this.$store.commit('options/cleanOptions');
              this.$store.commit('options/setOldOptions', {});

              this.$store.commit('types/update', false);
              this.$store.commit('applySet/openModal', false);
              const IsEditFromSet = this.$store.getters['packages/IsEditFromSet']
              const isEditOrderItem = this.$store.getters['orderItem/get']('isUpdate');
              const IsEditFromPackage = this.$store.getters['packages/IsEditFromPackage']
              if (IsEditFromSet && !isEditOrderItem) {
                router.push({name: 'sets'});
                this.$store.commit('packages/setIsEditFromSet', false);
              }
              if (IsEditFromPackage && !isEditOrderItem) {
                router.push({name: 'packages'});
                this.$store.commit('packages/setIsEditFromPackage', false)
              }
              if (isEditOrderItem) {
                this.$store.commit('orderItem/set', {k: 'isUpdate', v: false});
              }
            },
            orderReport() {
                this.$store.dispatch('orderPreview/productionReview', this.items.order_id)
            },

            formatName(item) {
                let name = item.name + ' - ' + item.colour_name,
                    insert = _.find(item.options, (o) => _.upperCase(o.option_name) === 'INS');

                if (!_.isUndefined(insert) && _.has(insert, 'colour')) {
                    let colorName = insert.colour.name;
                    if (_.isUndefined(colorName)) {
                        colorName = item.colour_name;
                    }
                    name += ' - ' + colorName
                }
                else if (!_.isUndefined(item.insert_colour_name) && item.insert_colour_name != null) {
                    name += ' - ' + item.insert_colour_name
                }
                return name;
            },

            bulkEdit(orderId) {
                this.goToBulkEdit(orderId);
            },

            setRoute() {
                this.currentRouteName = this.$router.currentRoute.name;
            },

            prepareEdit(itemData, order, room) {
                if (this.canEdit) {
                    this.$store.commit('orderPosition/editItem', itemData.item_id);
                }
            },

            deleteItem(itemId, orderId, location) {
                this.$store.commit('deleteModalGlobal/setStatus', true);
                this.$store.commit('deleteModalGlobal/setParams', {
                    itemType: 'oi',
                    action: 'orderItem/delete',
                    actionAfter: {
                        action: 'orderPreview/reloadLocation',
                        action: 'orderPreview/getPreviewLessInfo',
                        data: location
                    },
                    actionData: {
                        id: orderId,
                        itemId: itemId,
                        location,
                    }
                });
            },

            openAccessories(e) {
                let target = e.target;
                if (target.closest('.accessories_count')) {
                    let items = target.closest('.accessories_count').querySelectorAll('.additional__wrap');
                    for (let i = 0; i < items.length; i++) {
                        items[i].classList.toggle('toggleList_open');
                        items[i].classList.toggle('additional__wrap_open');
                    }
                }
                else {
                    target.closest('.toggleList').classList.toggle('toggleList_open');
                }
            },

            backStep() {
                localStorage.removeItem('user_id');
                localStorage.removeItem('order_id');
                router.push({name: 'dashboard'});
            },

            addLocation() {
                if (this.order_id) {

                    this.$store.commit('orderPosition/setOrderId', this.order_id);
                    this.$store.commit('orderPreview/positionBuilder', true);
                    this.$store.commit('orderPosition/nextStep', 'Location');

                    this.$store.commit(
                        'app/set',
                        {
                            k: 'breadcrumbs',
                            v: [{
                                route: {
                                    path: '/order-preview'
                                },
                                name: 'Select Location',
                            }]
                        }
                    );
                    this.$store.commit('app/setCreateOrder', true);
                }
                else {
                    alert("Order does not exist.");
                    window.location = '/customer-details';
                }
            },

            closeSelect() {
                this.$store.commit('orderPreview/positionBuilder', false);
                router.push(`/order-preview/${this.items.order_id}`);
                if (localStorage.getItem('order_url') == this.$store.getters['createdOrder/createdOrder'].id) {
                    window.location = '/order-preview/' + localStorage.getItem('order_url');
                    localStorage.removeItem('order_url');
                }
                this.$store.dispatch('accessories/cleanup');

            },

            openWarning() {
                this.$store.commit('createdOrder/warningModal', true);
            },

            addItem(item, location) {
                this.$store.commit('orderPosition/setOrderId', item.order_id);

                this.$store.commit('orderPosition/setLocation', location.name);

                this.$store.commit('app/setCreateOrder', false);
                this.$store.commit('orderPreview/positionBuilder', true);

            },

            counter(elem) {
                if (!elem) {
                    return false;
                }
                return _.toInteger(Object.keys(elem).length);
            },

            addAdditionalAccessories() {
                this.$store.commit('orderPreview/additionalAccessory', {open: true});

            },

            editAdditionalAccessory(item, index) {

                this.$store.dispatch('orderPreview/editAccessory', item);
            },

            deleteItemAccessory(item) {
                this.$store.dispatch('orderPreview/deleteAccessory', item.order_item_id);
            },

            itemName: function (name) {
                if (name !== "Accessories") return name;
                return 'Additional ' + name;
            },

            setLocations(locations) {
              // if (!location.lenth) return;
              this.locations =  _.filter(locations, function (location) {
                if (location.name !== "Accessories") return location
              });
            },

            locationListCreate() {
                if (!this.items.locations) return;
                return _.filter(this.items.locations, function (location) {
                    if (location.name !== "Accessories") return location
                });
            },

            checkCustom(elem) {
                let custom = false;
                for (let k in elem.items) {
                    if (k === 'custom') {
                        return custom = true;
                    }
                }
            },

            cloneActions() {

              if (_.filter(this.warning, i => { return i.type === 'BasiX-22'}).length > 0)
              {
                let $store = this.$store,
                  order_id = this.items.order_id

                this.$store.commit('genericQuestion/openModal', {question: 'This order contains BasiX products that have been discontinued or specification has changed. Use Bulk Edit function to change these items to another product or click Proceed to clone without these BasiX products',
                skip: false,
                buttons: [
                  {
                    name: 'Skip BasiX items and Clone',
                    handler: function () {
                      $store.dispatch('orderPreview/cloneOrder', order_id);
                      $store.commit('genericQuestion/closeModal')
                    }
                  },
                  {
                    name: 'Back',
                    handler: function () {
                      $store.commit('genericQuestion/closeModal')
                    }
                  }]
                })

                return
              }

                this.$store.dispatch('orderPreview/cloneOrder', this.items.order_id);
            },

            deleteActions() {
                this.$store.commit('orderPreview/delete', true);
            },

            isSubmit(id) {
              if (_.filter(this.warning, i => { return i.type === 'BasiX-22'}).length > 0){
                let $store = this.$store

                this.$store.commit('genericQuestion/openModal', {question: 'This order contains BasiX products that have been discontinued or specification has changed thus cannot be submitted to production. Use Bulk Edit function to change these items to another product or remove them.',
                  skip: false,
                  buttons: [
                    {
                      name: 'Back',
                      icon: 'solid/step-backward',
                      handler: function () {
                        $store.commit('genericQuestion/closeModal')
                      }
                    }]
                })

              }else {
                const submitActions = this.submitActions
                store.commit('genericQuestion/openModal', {
                    question: `Are you sure you want to submit this order to production?`,
                    buttons: [
                      {
                        name: 'Back',
                        icon: 'solid/step-backward',
                        handler: function () {
                          return false
                        }
                      },
                      {
                        name: 'Submit',
                        icon: 'solid/industry-windows',
                        handler: function (skip) {
                          submitActions(id)
                        }
                      },
                    ]
                  }
                )
              }

            },
            closeSubmit() {
                this.ask_submit = false;
                this.submit_id = 0;
            },


            submitActions() {
                this.$store.dispatch('orderPreview/submitOrder', this.items.order_id);
                this.ask_submit = false
            },

          quoteActions(){
            this.$store.dispatch('orderPreview/quoteOrder', this.items.order_id);
          },

            sendQuoteActions() {
              let quoteActions = this.quoteActions
              store.commit('genericQuestion/openModal', {
                  question: `Are you sure you want to email this QUOTE to your client ${this.items.customer.name}`,
                  buttons: [
                    {
                      name: 'Back',
                      icon: 'solid/step-backward',
                      handler: function () {
                        return false
                      }
                    },
                    {
                      name: 'Send Quote',
                      icon: 'solid/tape',
                      handler: function (skip) {
                        quoteActions();
                      }
                    },
                  ]
                }
              )

            },
          estimateActions() {
            this.$store.dispatch('orderPreview/estimateOrder', this.items.order_id);
          },
            sendEstimateActions() {
              let estimateActions = this.estimateActions
              store.commit('genericQuestion/openModal', {
                  question: `Are you sure you want to email this ESTIMATE to your client ${this.items.customer.name}`,
                  buttons: [
                    {
                      name: 'Back',
                      icon: 'solid/step-backward',
                      handler: function () {
                        return false
                      }
                    },
                    {
                      name: 'Send Estimate',
                      icon: 'solid/clipboard-list',
                      handler: function (skip) {
                        estimateActions()
                      }
                    },
                  ]
                }
              )
            },

            cancelActions() {
                this.$store.commit('orderPreview/cancel', true);
            },

            disabledBtn() {
                if (this.items.locations) {
                    return false;
                }
                else return true;
            },

            openSettings() {
                return this.$store.commit('orderPreview/setSettingsWindow', true);
            },

            openSets() {
                this.$store.commit('applySet/openModal', true);
            },

            openCustomerWindow() {
                this.$store.commit('customerDetails/customerDetailsOpen', true);
            },

            checkOpenModal() {
                if (!this.selectModal) {
                    router.push(`/order-preview/${this.items.order_id}`);
                }
            },

            ...mapMutations('app', ['setPrevBreadcrumbs']),

            backStepInCreate() {
                if (!this.isCompleted) {
                    if (!_.isArray(this.breadcrumbs)) {
                        return false
                    }

                    if (this.breadcrumbs.length - 1 < 0) {
                        return false
                    }

                  eventBus.emit(
                        'go-back-breadcrumbs',
                        {
                            breadcrumb: this.breadcrumbs[this.breadcrumbs.length - 1],
                            index: this.breadcrumbs.length - 1
                        }
                    );
                }else{
                    // this.$store.commit('orderPosition/goToStep', 'All Options');
                    this.$store.dispatch('orderPosition/guessPrevStep')
                }

            },

            accessoriesTotal() {
                var p = 0;
                _.each(this.items.accessories, (i) => {
                    p += Number(i.price);
                })
                return p.toFixed(2);
            },


            calcPrice(price) {
                return changePriceForm(price);
            }
        },

        computed: {
            ...mapGetters({
                items: 'orderPreview/items',
                selectModal: 'orderPreview/positionBuilder',
                warningModal: 'createdOrder/warningModal',
                openSettingsOrder: 'orderPreview/settingsWindow',
                openTotalOrder: 'orderPreview/totalPriceWindow',
                order_id: 'orderPreview/id',
                cancelOpen: 'orderPreview/cancel',
                cloneWindow: 'orderPreview/cloneWindow',
                estimateQuote: 'orderPreview/estimateQuote',
                deleteOpen: 'orderPreview/delete',
                currentStep: 'orderPosition/currentStep',
                currentPosition: 'orderPosition/orderPosition',
                isCompleted: 'orderPosition/isCompleted',
                additionalAccessory: 'orderPreview/additionalAccessory',
                itemPrice: 'orderPosition/getPrice',
                updatePrice: 'orderPosition/updatePrice',
                can: 'auth/can',
                isStepOnTypeStyleGradeColour: 'orderPosition/isStepOnTypeStyleGradeColour',
            }),

            filterACCKeyGrateThanOne() {

              return _.filter(this.items.accessories,(accessory, accKey)=> {
                    return (accKey > 1 && isExpandAdditionalAccessories) || accKey <= 1
              })
            },

            breadcrumbs() {
                return this.$store.getters['app/get']('breadcrumbs');
            },
            ...mapState(['applySet', ['openModal']]),
            ...mapState(['customerDetails', ['customerDetailsWindow']]),
            ...mapState('customerDetails', ['successModal']),
            ...mapState('orderItem', ['isUpdate']),
            ...mapState('orderPreview', ['incomplete', 'locationLoadingStatus']),
            ...mapState('app', ['createOrder']),
            ...mapState('orderPosition', ['stepsHistory']),

            locationsAreLoading() {
                let result = false
                for(let loc in this.items.locations) {
                  if(_.get(this.locationLoadingStatus,`${loc}.isLoading`, undefined)) {
                    result = true
                    break
                  }
                }
                return result
            },

            deleteGlobalOpen() {
                return this.$store.getters['deleteModalGlobal/get']('status');
            },

            getUserName() {
                return localStorage.getItem('nameUser');
            },

            // showBackButton() {
            //     return (this.currentStep !== 'All Options') //TODO: Review-Kiruba && !this.currentPosition.id
            // },
            canEdit() {
              return _.get(this.items, 'actions.can_edit', false)
            }
        },

        created() {
//      this.setRoute();
            localStorage.setItem('order_id', this.$route.params['id']);
            //
            // if (localStorage.getItem('order_id') == 'undefined' || localStorage.getItem('order_id') == null) {
            // }
            this.$store.commit('orderPreview/id', localStorage.getItem('order_id'));
            if (localStorage.getItem('order_id')) {
                return this.$store.dispatch('orderPreview/getPreviewLessInfo', {silent: true});
            }
        },

        mounted() {
            this.$store.registerModule('bulkEdit', bulkEdit);
            this.$store.dispatch('types/getTypes', true);
            this.$store.dispatch('auth/updateAbilities')
        },

        updated() {
            this.checkOpenModal();
        },

        beforeDestroy() {
            this.$store.unregisterModule('bulkEdit');
        },
    }
</script>

<style scoped lang="scss">

    @import '../../../src/assets/scss/utils/vars';
    @import '../../../src/assets/scss/utils/helper';

    .handle {
      .handle-icon-wrapper {
        padding-left: 10px;
      }

      .handle-icon {
        color: $green;
        transition: all .3s linear;
      }

      &:hover {
        .handle-icon {
          color: darken($green, 10%);
        }
      }

    }

    .set__itemDelete {
      position: initial;
    }

    .btn {
        margin-right: 2rem;
    }

    .modal_additional .btn {
        margin-top: 40px;
    }

    .thumbnailsTable {
        max-width: 200px;
        margin: 0 auto;
        padding: 1rem;
        cursor: pointer;
    }

    .orderItem__listItem {
        display: block;
    }

    .accessories_list__wrapper .toggleList__title {
        width: 100%;
    }

    @media screen and (max-width: $sm) {
        .thumbnailsTable {
            position: static;
            transform: none;
        }
    }

    .orderItem {
        &_open {
            .orderItem__listItems {
                max-height: none;
                overflow: hidden;
            }
        }
    }

    .orderItem__listItems {
        max-height: none;
        /*overflow: auto;*/
    }

    .accessories_count {
        margin-bottom: 20px;
    }

    .modal {
        &_p90 {
            padding-bottom: 90px;
        }
    }

    .text_white_1 {
        //position: absolute;
        //bottom: 3rem;
        //left: auto;
        //right: 2rem;
        display: inline-block;
        width: auto;
        color: white;

    }

</style>


