<template>
  <div class="orderItem" :class="{'bulk-edit': isBulkEdit}">
    <div class="orderItem__listItems">
      <div class="relative d-flex align-items-center handle">
        <div class="flex-1 handle-icon-wrapper">
          <svg-icon name="grip-vertical" v-if="!isBulkEdit"  size="lg" class="handle-icon"/>
        </div>
        <h3 class="orderItem__listTitle text_green text_center location_name" :title="location.name">{{ location.name }}</h3>
        <div class="flex-1"></div>
      </div>
      <template v-if="itemsLoading">

        <ContentLoader v-for="i in 1" class="px-2" height="100">
          <rect x="70" y="10" rx="5" ry="5" width="300" height="15"/>
          <rect x="70" y="39" rx="5" ry="5" width="220" height="9"/>
          <rect x="70" y="55" rx="5" ry="5" width="220" height="9"/>
          <rect x="70" y="70" rx="5" ry="5" width="220" height="9"/>
          <rect x="0" y="10" rx="0" ry="0" width="60" height="70"/>
        </ContentLoader>
      </template>
      <div v-else>
        <div class="tw-grid" :class="`${location.items.standard.length ===1? 'tw-grid-rows-1': '' }`">
          <div class="toggleList"
               v-for="(elem, key) in filterStandardsItems"
               :key="key">
            <div class="orderItem__iconBox">
              <img :src="elem.icon"
                   :alt="elem.name"
                   class="orderItem__img">
            </div>
            <h5 class="toggleList__title"
                :class="{
                  'toggleList__title_empty': !canEdit,
                  'pr-4': isBulkEdit,
                  'text_red': elem.is_incomplete
                }"
            >
              <span
                v-if="elem.custom"
                class="text_red text_bold">Custom
              </span>
              <span v-else>
                 <!-- Define width or drop based on user settings -->
                {{( isAdmin() || isFirstDimensionWidth() ) ? `${elem.width} x ${elem.drop}` : `${elem.drop} x ${elem.width}` }}
              </span>

              <!--{{ formatName(elem) }}-->{{elem.name}} - {{elem.colour}}
              <span v-if="elem.custom" class="text_red"> - *Call for a quote</span>
              <p class="text_green toggleList__trigger"
                    v-if="elem.accessories > 0"
                    @click="openAccessories($event)">
                +{{ elem.accessories }} accessories
              </p>
              <ul class="toggleList__wrap" v-if="additionalData(elem.item_id)">
                <li class="toggleList__item" v-if="additionalData(elem.item_id).loading">
                  Loading data...
                </li>
                <li class="toggleList__item" v-else
                    v-for="accessory in additionalData(elem.item_id).accessories">{{ accessory }}
                </li>
              </ul>

              <p class="text_green toggleList__trigger"
                    v-if="elem.available_options > 0"
                    @click="openAccessories($event)">
                +{{ elem.available_options }} available options
              </p>
              <ul class="toggleList__wrap" v-if="additionalData(elem.item_id)">
                <li class="toggleList__item" v-if="additionalData(elem.item_id).loading">
                  Loading data...
                </li>
                <li class="toggleList__item" v-else
                    v-for="option in additionalData(elem.item_id).options">{{ option }}
                </li>
              </ul>
              <a href="#incomplete" class="text_red" v-if="elem.is_incomplete">*</a>

              <span class="set__itemDelete"
                    @click="deleteItem(elem.item_id, orderId, location.name)"
                    v-if="canEdit"
              >
                <svg-icon name="solid/trash"/>
              </span>

              <span class="set__itemDelete"
                    @click="prepareEdit(elem, 'items', location.name)"
                    v-if="canEdit"
              >
                <svg-icon name="edit"/>
              </span>
              <slot name="action-item" v-bind:elem="elem" />
            </h5>

            <p v-if="can(abilityNames.SEE_PRICE)" class="toggleList__price text_green"
               :class="{'text_red': elem.is_incomplete}"
            >${{ calcPrice(Number(elem.amount).toFixed(2)) }}</p>
          </div>
        </div>
      </div>
      <template v-if="itemsLoading">
        <div class="orderItem__price d-flex justify-content-between">
          <span>{{location.item_count}} Item{{location.item_count >1? 's': ''}}</span>
          <span v-if="can(abilityNames.SEE_PRICE)">
          aud
          ${{ calcPrice(Number(location.amount).toFixed(2)) }}
          </span>
        </div>
      </template>
      <template v-else>
        <div class="orderItem__price d-flex justify-content-between" v-if="location.items.standard">
          <div class="tw-flex tw-flex-row tw-w-full tw-justify-between tw-items-center">
          <span>{{location.item_count}} Item{{location.item_count >1? 's': ''}}</span>
            <div @click="showMore = !showMore" v-if="location.items.standard.length > 1" class="tw-inline-flex tw-cursor-pointer tw-text-2xl hover:tw-text-green-600 tw-transition tw-transition-all">
              <svg-icon :name="showMore? 'solid/chevron-up': 'solid/chevron-down'"/>
            </div>
          <span v-if="can(abilityNames.SEE_PRICE)">
          aud
          ${{ calcPrice(Number(location.amount).toFixed(2)) }}
            </span>
          </div>
        </div>
      </template>
    </div>

    <template v-if="!itemsLoading">
      <div class="orderItem__divider" v-if="location.items.custom"></div>

      <div class="orderItem__listItems" v-if="location.items.custom">

        <h3 class="orderItem__listTitle text_red text_center">Custom</h3>

        <div class="toggleList"
             v-for="(elem, key) in location.items.custom"
             :key="key">

          <img :src="elem.icon"
               :alt="elem.name"
               class="orderItem__img">
          <h5 class="toggleList__title"
              :class="{'toggleList__title_empty': code !== 'DRFT'}"
          >

            <span v-if="elem.custom" class="text_red text_bold">Custom</span>
            <span v-else>{{ elem.width }} x {{ elem.drop }}&nbsp;</span>
            <!--{{  formatName(elem) }}-->{{elem.name}} - {{elem.colour}}
            <span v-if="elem.custom" class="text_red"> - *Call for a quote</span>
            <span class="text_green toggleList__trigger"
                  v-if="elem.accessories > 0"
                  @click="openAccessories($event)">
            +{{ elem.accessories }} accessories
          </span>
            <span class="text_green toggleList__trigger"
                  v-if="elem.available_options > 0"
                  @click="openAccessories($event)">
            +{{ elem.available_options }} available options
          </span>

            <span class="set__itemDelete"
                  @click="deleteItem(elem.item_id, orderId)"
                  v-if="code === 'DRFT'">
            <svg-icon name="times"/>
          </span>

            <span class="set__itemDelete"
                  @click="prepareEdit(elem, 'items', location.name)"
                  v-if="code === 'DRFT'">
            <svg-icon name="edit"/>
          </span>
          </h5>

          <ul class="toggleList__wrap" v-if="additionalData(elem.item_id)">
            <li class="toggleList__item" v-if="additionalData(elem.item_id).loading">
              Loading data...
            </li>
            <li class="toggleList__item" v-else
                v-for="accessory in additionalData(elem.item_id).accessories">{{ accessory }}
            </li>
          </ul>

        </div>

        <div class="orderItem__price">
          <span class="text_red text_bold">Call for a quote</span>
        </div>
      </div>

      <div class="orderItem__footer" v-if="(code === 'DRFT' || code === 'REVW') && !isBulkEdit">
        <button class="btn_link" type="button"
                @click="addItem({order_id: orderId}, location)">
          <svg-icon name="plus"/>
          Add item to Location
        </button>
      </div>
    </template>
  </div>
</template>

<script>

import { ContentLoader } from 'vue-content-loader'
import { mapState, mapGetters, mapActions } from 'vuex';
import * as abilityNames from '@/components/../helpers/abilityNames'


export default {
    name: 'Location',
    components: {
      ContentLoader
    },
    data() {
      return {
          abilityNames,
        showMore: false,
      }
    },
    props: ['location', 'counter', 'addItem', 'code', 'canEdit', 'formatName', 'calcPrice', 'deleteItem', 'openAccessories', 'prepareEdit', 'isBulkEdit'],
    computed: {
        ...mapGetters({
            orderId: 'orderPreview/id',
            currentPosition: 'orderPosition/orderPosition',
            additionalData: 'orderPreview/additionalData',
            can: 'auth/can'
        }),
        ...mapState('orderPreview', ['locationLoadingStatus']),
        itemsLoading() {
           return this.locationLoadingStatus[this.location.name].isLoading
        },
        filterStandardsItems() {
          return _.filter(this.location.items.standard,(elem, key)=> {
                return (key >= 1 && this.showMore) || key < 1
          })
       }
    },
    // watch: {
      // currentPosition: {
      //   handler: function (val, oldVal) {
      //     if((oldVal.location.trim() === this.location.name.trim() && val.location === '')) {
      //       // this.reloadItems()
      //     }
      //   },
      //   deep: true,
      // }
    // },
    created() {
      this.loadItems()
    },
    methods: {
      ...mapActions('orderPreview', ['loadLocation', 'reloadLocation']),
      loadItems() {
        this.loadLocation(this.location.name)
      },
      reloadItems() {
        this.reloadLocation(this.location.name)
      },
    }
}
</script>

<style scoped lang="scss">

  @import '../../../src/assets/scss/utils/vars';
  @import '../../../src/assets/scss/utils/helper';

  .handle {
    .handle-icon-wrapper {
      padding-left: 10px;
    }

    .handle-icon {
      cursor: move;
      color: $green;
      transition: all .3s linear;
    }

    &:hover {
      .handle-icon {
        color: darken($green, 10%);
      }
    }

  }
  .location_name{
    text-overflow: ellipsis;

    /* Required for text-overflow to do anything */
    white-space: nowrap;
    overflow: hidden;
  }

</style>
